import React from "react";

import Video from "./Video";


const Section = () => {

  return (
        <Video />
  );
};

export default Section;
